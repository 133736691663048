define("discourse/plugins/discourse-jira/discourse/connectors/category-custom-settings/jira-settings", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "discourse/helpers/with-event-value", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _withEventValue, _ajax, _ajaxError, _I18n, _comboBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class JiraSettings extends _component.default {
    static shouldRender(args, container) {
      return container.siteSettings.discourse_jira_enabled;
    }
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "projects", [_tracking.tracked], function () {
      return [];
    }))();
    #projects = (() => (dt7948.i(this, "projects"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "projectId", [_tracking.tracked], function () {
      return null;
    }))();
    #projectId = (() => (dt7948.i(this, "projectId"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "issueTypeId", [_tracking.tracked], function () {
      return null;
    }))();
    #issueTypeId = (() => (dt7948.i(this, "issueTypeId"), void 0))();
    constructor() {
      super(...arguments);
      this.preflightChecks();
    }
    get category() {
      return this.args.outletArgs.category;
    }
    get issueTypes() {
      const project = this.projects.findBy("id", this.projectId);
      return project ? project.issue_types : [];
    }
    onChangeProject(projectId) {
      this.projectId = projectId;
      this.category.custom_fields.jira_project_id = projectId;
    }
    static #_5 = (() => dt7948.n(this.prototype, "onChangeProject", [_object.action]))();
    onChangeIssueType(issueTypeId) {
      this.issueTypeId = issueTypeId;
      this.category.custom_fields.jira_issue_type_id = issueTypeId;
    }
    static #_6 = (() => dt7948.n(this.prototype, "onChangeIssueType", [_object.action]))();
    async preflightChecks() {
      this.loading = true;
      try {
        const result = await (0, _ajax.ajax)("/jira/issues/preflight");
        if (result.projects) {
          this.projects = result.projects;
          this.projectId = this.category.custom_fields.jira_project_id;
          this.issueTypeId = this.category.custom_fields.jira_issue_type_id;
        }
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "preflightChecks", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="jira-settings">
          <h3>{{t "discourse_jira.category_setting.title"}}</h3>
          <section class="field jira-project">
            <label>{{t "discourse_jira.category_setting.project"}}</label>
            <div class="controls">
              <ComboBox
                @name="project_id"
                @content={{this.projects}}
                @valueProperty="id"
                @value={{this.projectId}}
                @onChange={{this.onChangeProject}}
              />
            </div>
          </section>
          <section class="field jira-project">
            <label>{{t "discourse_jira.category_setting.issue_type"}}</label>
            <div class="controls">
              <ComboBox
                @name="issue_type"
                @content={{this.issueTypes}}
                @value={{this.issueTypeId}}
                @onChange={{this.onChangeIssueType}}
              />
            </div>
          </section>
          <section class="field jira-project">
            <label>{{t
                "discourse_jira.category_setting.num_likes_auto_create_issue"
              }}</label>
            <div class="controls">
              <input
                type="number"
                min="1"
                value={{this.category.custom_fields.jira_num_likes_auto_create_issue}}
                {{on
                  "input"
                  (withEventValue
                    (fn
                      (mut
                        this.category.custom_fields.jira_num_likes_auto_create_issue
                      )
                    )
                  )
                }}
              />
            </div>
          </section>
        </div>
      
    */
    {
      "id": "J3FOZV9U",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"jira-settings\"],[12],[1,\"\\n      \"],[10,\"h3\"],[12],[1,[28,[32,0],[\"discourse_jira.category_setting.title\"],null]],[13],[1,\"\\n      \"],[10,\"section\"],[14,0,\"field jira-project\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,[28,[32,0],[\"discourse_jira.category_setting.project\"],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@name\",\"@content\",\"@valueProperty\",\"@value\",\"@onChange\"],[\"project_id\",[30,0,[\"projects\"]],\"id\",[30,0,[\"projectId\"]],[30,0,[\"onChangeProject\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"section\"],[14,0,\"field jira-project\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,[28,[32,0],[\"discourse_jira.category_setting.issue_type\"],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@name\",\"@content\",\"@value\",\"@onChange\"],[\"issue_type\",[30,0,[\"issueTypes\"]],[30,0,[\"issueTypeId\"]],[30,0,[\"onChangeIssueType\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"section\"],[14,0,\"field jira-project\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,[28,[32,0],[\"discourse_jira.category_setting.num_likes_auto_create_issue\"],null]],[13],[1,\"\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[11,\"input\"],[24,\"min\",\"1\"],[16,2,[30,0,[\"category\",\"custom_fields\",\"jira_num_likes_auto_create_issue\"]]],[24,4,\"number\"],[4,[32,2],[\"input\",[28,[32,3],[[28,[32,4],[[28,[31,0],[[30,0,[\"category\",\"custom_fields\",\"jira_num_likes_auto_create_issue\"]]],null]],null]],null]],null],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"mut\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-jira/discourse/connectors/category-custom-settings/jira-settings.js",
      "scope": () => [_I18n.t, _comboBox.default, _modifier.on, _withEventValue.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = JiraSettings;
});