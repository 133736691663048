define("discourse/plugins/discourse-jira/discourse/components/jira-field", ["exports", "@glimmer/component", "discourse/plugins/discourse-jira/discourse/components/fields/dj-date-field", "discourse/plugins/discourse-jira/discourse/components/fields/dj-dropdown-field", "discourse/plugins/discourse-jira/discourse/components/fields/dj-multiselect-field", "discourse/plugins/discourse-jira/discourse/components/fields/dj-text-field", "discourse/plugins/discourse-jira/discourse/components/fields/dj-textarea-field", "@ember/component", "@ember/template-factory"], function (_exports, _component, _djDateField, _djDropdownField, _djMultiselectField, _djTextField, _djTextareaField, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class JiraField extends _component.default {
    get component() {
      switch (this.args.field.field_type) {
        case "string":
          const textAreaFields = ["description", "summary"];
          if (textAreaFields.includes(this.args.field.key)) {
            return _djTextareaField.default;
          }
          return _djTextField.default;
        case "array":
          return _djMultiselectField.default;
        case "option":
          return _djDropdownField.default;
        case "date":
          return _djDateField.default;
        default:
          return _djTextField.default;
      }
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <this.component
          @field={{@field}}
          @saveIssue={{@saveIssue}}
          @label={{@field.name}}
        />
      
    */
    {
      "id": "b90vXJXe",
      "block": "[[[1,\"\\n    \"],[8,[30,0,[\"component\"]],null,[[\"@field\",\"@saveIssue\",\"@label\"],[[30,1],[30,2],[30,1,[\"name\"]]]],null],[1,\"\\n  \"]],[\"@field\",\"@saveIssue\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-jira/discourse/components/jira-field.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = JiraField;
});